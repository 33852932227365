import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { FlexboxGrid, IconButton, Dropdown } from "rsuite";
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";

import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { padding } from "@mui/system";
import { unset } from "lodash";
// import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
var presentPage;
const Pagination = ({
  nftList,
  setViewNftList,
  onPageChange,
  onNoOfItemChange,
  noOfItemsPage,
  presentPge,
  instance,
}) => {
  presentPage = presentPge;
  const [itemsPerPage, setItemsPerPage] = useState(noOfItemsPage);

  const itemsPerPageList = [18, 96, 198];
  const listLength = nftList.length;
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPageNumber, setLastPageNumber] = useState(0);
  const maxPageCount = Math.ceil(listLength / noOfItemsPage);

  //   useEffect(() => {
  //     setCurrentPage(0);
  //   }, [displayingList]);

  useEffect(() => {
    const list = nftList.slice(
      noOfItemsPage * currentPage,
      (currentPage + 1) * noOfItemsPage
    );
    console.log(`40 presentPage ${currentPage}`);
    onPageChange(currentPage);
    setItemsPerPage(noOfItemsPage);
    // onNoOfItemChange(itemsPerPage);
    setViewNftList(list);
  }, [currentPage, nftList, noOfItemsPage]);

  // const handleChange = (eventKey) => {
  //     console.log(eventKey)
  // setItemsPerPage(eventKey);
  // }

  const handleChange = (value) => {
    console.log("54----------------", value);

    setItemsPerPage(noOfItemsPage);

    onNoOfItemChange(value.target.value);
    setCurrentPage(0);
  };

  const leftOnClick = () => {
    if (presentPage !== 0) {
      setCurrentPage(presentPage - 1);
    }
  };
  const rightOnClick = () => {
    console.log(
      `presentPage ${presentPage}  currentPage ${currentPage} maxPageCount ${maxPageCount}`
    );
    if (presentPage < maxPageCount - 1) {
      ++presentPage;
      console.log(`presentPage ${presentPage}`);
      setCurrentPage(presentPage);
    }
  };
  const lastPage = () => {
    console.log("77 presentPage ", presentPage);

    if (presentPage == maxPageCount - 1) return listLength;
    else return (presentPage + 1) * noOfItemsPage;
  };

  const style = {
    padding: "0px",
  };

  return (
    <div>
      <Card>
        <CardContent style={style}>
          <FlexboxGrid
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FlexboxGridItem colspan={4}>
              Rows Per Page{" "}
              <Select
                size="small"
                labelId="demo-select-small"
                id="demo-select-small"
                value={noOfItemsPage}
                name={instance}
                onChange={handleChange}
              >
                {itemsPerPageList.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FlexboxGridItem>

            <FlexboxGridItem colspan={3}>
              <IconButton icon={<FaLessThan />} onClick={leftOnClick} />
              {presentPage * noOfItemsPage + 1} - {lastPage()} of {listLength}
              <IconButton icon={<FaGreaterThan />} onClick={rightOnClick} />
            </FlexboxGridItem>
          </FlexboxGrid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Pagination;
