import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OpenSeaPort } from "opensea-js";
import { OrderSide } from "opensea-js/lib/types";
import Web3 from "web3";
import { getsinglenft } from "../redux/fetchsinglenft/fetchsinglenftActions";
import Loader from "../components/Loader";
import Error from "../components/Error";
import config from "../config.json";
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Container,
  Form,
} from "react-bootstrap";
import { SwapWidget } from "@uniswap/widgets/dist/index.js";
import "@uniswap/widgets/dist/fonts.css";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ethlogo from "../assets/ethereum.png";
import Footer from "../components/Footer";
import { EventType } from "opensea-js";
import Stripe from "../components/Stripe";

var url;
// var current_node;
var openSeaDomain = config.OPENSEA_MAIN;
var X_API_KEY = config.MAIN_X_API_KEY;

var web3 = new Web3(Web3.givenProvider);


const provider = Web3.givenProvider;

const theme = {
  primary: "#000",
  secondary: "#666",
  interactive: "#f2f2f2",
  container: "#FFF",
  module: "#f2f2f2",
  accent: "#3D3B31",
  outline: "#343D3A",
  dialog: "#FFF",
  fontFamily: "Verdana",
  borderRadius: 0.8,
};

const NftDetailPage = ({blockchain,accountAddress}) => {

  const [ownership, setOwnership] = useState(null);
  const [walletowner, setWalletowner] = useState();
  const [listing, setListing] = useState([]);
  const [listingZero, setlistingZero] = useState();
  const [buttonState, setButtonState] = useState();
  const [usdrate, setUsdrate] = useState();
  const [offerOrders, setOfferOrders] = useState();
  const [listingendDate, setlistingendDate] = useState(new Date());
  const [value, setValue] = React.useState(
    new Date("2018-01-01T00:00:00.000Z")
  );

  const [ethBalance, setEthBalance] = useState();
  const [wethBalance, setWethBalance] = useState();
  const [ercTokenType, setErcTokenType] = useState();

  const [ethAmount, setEthAmount] = useState();
  const [noOfDays, setNoOfDays] = useState(
    Math.round(Date.now() / 1000 + 60 * 60 * 24 * 3)
  );
  const [offer, setOffer] = useState();
  const [offerError, setError] = useState();
  const [uniswapShow, setUniswapShow] = useState(false);
  const [uniswapBidShow, setUniswapBidShow] = useState(false);
  const [biddingValiError, setbiddingValiError] = useState();

  const [buyNftRes, setBuyNftRes] = useState();
  const [buyNftTxnHash, setBuyNftTxnHash] = useState();
  const [buyingTrueorFalse, setBuyingTrueorFalse] = useState(false);

  const [seaport, setSeaport] = useState()

  let { contractAddress, token_Id } = useParams();


  const [cardbuying, setcardBuying] = useState(false);
  const handlecardbuyingClose = () => {
    setError(null);
    setcardBuying(false);
  };
  const handlecardbuyingShow = () => setcardBuying(true);




  const [buying, setBuying] = useState(false);
  const handlebuyingClose = () => {
    setError(null);
    setBuying(false);
  };
  const handlebuyingShow = () => setBuying(true);

  const [buyingalert, setBuyingalert] = useState(false);
  const handlebuyingalertClose = () => {
    // setErrorMessage(null)
    setBuyingalert(false);
  };
  const handlebuyingalertShow = () => setBuyingalert(true);

  const [bidding, setBidding] = useState(false);
  const handlebiddingClose = () => {
    setError(null);
    setBidding(false);
  };
  const handlebiddingShow = () => setBidding(true);

  const [biddingalert, setBiddingalert] = useState(false);
  const handlebiddingalertClose = () => {
    // setErrorMessage(null)
    setBiddingalert(false);
  };
  const handlebiddingalertShow = () => setBiddingalert(true);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setErrorMessage(null)
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showoffer, setShowoffer] = useState(false);
  const handleCloseoffer = () => {
    // setErrorMessage(null)
    setShowoffer(false);
  };
  const handleShowoffer = () => setShowoffer(true);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const nftstate = useSelector((state) => state.getsinglenftReducer);
  const { nft, loading, error } = nftstate;

  let tokenABI = [
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [
        {
          name: "",
          type: "string",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_spender",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          name: "",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_from",
          type: "address",
        },
        {
          name: "_to",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [
        {
          name: "",
          type: "uint8",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          name: "balance",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [
        {
          name: "",
          type: "string",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          name: "_to",
          type: "address",
        },
        {
          name: "_value",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          name: "",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
        {
          name: "_spender",
          type: "address",
        },
      ],
      name: "allowance",
      outputs: [
        {
          name: "",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      payable: true,
      stateMutability: "payable",
      type: "fallback",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
  ];

  useEffect(() => {
    dispatch(getsinglenft(contractAddress, token_Id));
    walletAddress();
    checkingOwnership();
    usdconversion();
    buttonConditionChecking();
    
  }, []);

  useEffect(() => {
    // setTimeout(gettingoffers(), 1000);
    // setTimeout(gettingAuctions(), 500);
    gettingoffers();
    gettingAuctions();
  }, [walletowner]);

  useEffect(() => {
    checkingOwnership();
  }, [walletowner, nft]);

  useEffect(() => {
    walletAddress();
  }, [uniswapShow, uniswapBidShow]);

  useEffect(() => {
    buttonConditionChecking();
  }, [listing, ownership, listingZero]);
  
  useEffect(() => {
    createSdkObj();
    
  }, [ownership]);


  const createSdkObj = async () => {
    
    if (blockchain != config.main_network_node) {
      openSeaDomain = config.OPENSEA_TEST;
      X_API_KEY = config.TEST_X_API_KEY;
    }
  
    let seaportObj = new OpenSeaPort(provider, {
      networkName: blockchain,
      apiKey: X_API_KEY,
    });
    setSeaport(seaportObj )

  }

  const usdconversion = () => {
    console.log(blockchain,accountAddress)
    var usdValue;
    try {
      const url = "https://api.binance.com/api/v3/avgPrice?symbol=ETHUSDT";
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          usdValue = response.price;
          setUsdrate(usdValue);
          console.log("ETH/USD' : ", usdValue);
        })
        .catch((err) => console.error(err));
    } catch (err) {}
  };

  const gettingoffers = async () => {
    const asset_contract_address = contractAddress;
    const token_id = token_Id;

    url = `${openSeaDomain}asset/${asset_contract_address}/${token_id}/offers?limit=20`;

    console.log("url---------------------------", url);

    const options = {
      method: "GET",
      headers: { Accept: "application/json", "X-API-KEY": X_API_KEY },
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.offers);
        try {
          if (response.offers.length !== 0) {
            setOfferOrders(response.offers);
          }
        } catch (err) {
          console.log("111 Listing retrived Error : ", err);
        }
      })
      .catch((err) => console.error(err));
    // console.log(orders)
    // console.log(count)

    // }
  };

  const gettingAuctions = async () => {
    const asset_contract_address = contractAddress;
    const token_id = token_Id;

    url = `${openSeaDomain}asset/${asset_contract_address}/${token_id}/listings?limit=20`;

    console.log("url---------------------------", url);

    const options = {
      method: "GET",
      headers: { Accept: "application/json", "X-API-KEY": X_API_KEY },
    };
    setTimeout(() => {
      fetch(url, options)
        .then((response) => response.json())
        .then((response) => {
          console.log(
            "====================================== 96 Response is : ",
            response
          );
          try {
            if (response.listings.length !== 0) {
              setListing(response.listings);
              setlistingZero(response.listings[0].payment_token);
              setlistingendDate(response.listings[0].closing_date);
            }
          } catch (err) {
            console.log("111 Listing retrived Error : ", err);
          }
        })
        .catch((err) => console.error(err));
    }, 1000);
  };

  const walletAddress = async() => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then(async (result) => {
        let wallet = result[0];
        let balance = await web3.eth.getBalance(result[0]);
        console.log("wallet is : ", wallet);
        console.log("balance is : ", balance);
        setWalletowner(wallet);

        const etherValue = Web3.utils.fromWei(balance, "ether");
        console.log("base_price is : ", etherValue);
        setEthBalance(etherValue);

        let contract = new web3.eth.Contract(
          tokenABI,
          "0xc778417E063141139Fce010982780140Aa0cD5Ab"
        );

        // const getWethBalance = async () => { // if want uncomment all this lines
        let wEbalance = await contract.methods.balanceOf(wallet).call();

        const wethValue = Web3.utils.fromWei(wEbalance, "ether");

        setWethBalance(wethValue);

        console.log("My wallet Add :", wallet);
        console.log("My wallet Wei ETH Bal :", balance);
        console.log("My wallet ETH Bal :", etherValue);
        console.log("My wallet WETH Bal :", wethValue);
        // return { wallet, balance, etherValue, wethValue }; // if want uncomment all this lines
        // };

        // getWethBalance().then(function (result) { // if want uncomment all this lines
        //   console.log("My result :", result); // if want uncomment all this lines
        // }); // if want uncomment all this lines
      })
      .catch((error) => {
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ : ", error);
      });
  };
  const checkingOwnership = () => {
    {
      nft && console.log(nft.top_ownerships[0].owner.address);
    }
    if (nft && walletowner) {
      if (
        nft.top_ownerships[0].owner.address === walletowner
        // String(nft.owner.address) ===
        //   "0x0000000000000000000000000000000000000000" &&
        // nft.creator.address === walletowner
      ) {
        setOwnership(true);
      }

      // else if (String(walletowner) === String(nft.owner.address)) {
      //   setOwnership(true);
      // }
      else {
        setOwnership(false);
      }
    }
    // console.log(ownership);
  };

  const offerCall = async () => {
   
    try {

      const schemaName = await nft.asset_contract.schema_name;

      // const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24);
      const expirationTime = parseInt(noOfDays);
      const tokenId = token_Id;
      const tokenAddress = contractAddress;
      const accountAddress = walletowner;

      const offer = seaport.createBuyOrder({
        asset: {
          tokenId,
          tokenAddress,
          schemaName, // WyvernSchemaName. If omitted, defaults to 'ERC721'. Other options include 'ERC20' and 'ERC1155'
        },
        accountAddress,
        // Value of the offer, in units of the payment token (or wrapped ETH if none is specified):
        startAmount: ethAmount,
        expirationTime,
      });
      offer
        .then((response) => {
          console.log(response);
          setOffer(response);
          handleCloseoffer();
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const biddingcall = async () => {
    try {
      const schemaName = await nft.asset_contract.schema_name;

      // const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24);

      const tokenId = token_Id;
      const tokenAddress = contractAddress;
      const accountAddress = walletowner;

      const offer = seaport.createBuyOrder({
        asset: {
          tokenId,
          tokenAddress,
          schemaName, // WyvernSchemaName. If omitted, defaults to 'ERC721'. Other options include 'ERC20' and 'ERC1155'
        },
        accountAddress,
        // Value of the offer, in units of the payment token (or wrapped ETH if none is specified):
        startAmount: ethAmount,
      });
      offer
        .then((response) => {
          console.log(response);
          setOffer(response);
          // handleCloseoffer()
          handlebiddingClose();
        })
        .catch((err) => {
          console.log(err.message);
          setError(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const buyingnft = async () => {
    try {
      const tokenAddress = contractAddress;
      const token_id = token_Id;

      const order = await seaport.api.getOrder({
        side: OrderSide.Sell,
        asset_contract_address: tokenAddress,
        token_id: token_id,
      });
      const accountAddress = await walletowner; // The buyer's wallet address, also the taker
      const transactionHash = await seaport.fulfillOrder({
        order,
        accountAddress,
      });

      console.log(transactionHash);
      console.log(order);
      setBuyNftRes(order);
      setBuyNftTxnHash(transactionHash)
       
        
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  function UniSwap(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="mx-auto">
            Add funds
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row className="d-flex justify-content-center">
              <SwapWidget
                provider={provider}
                // jsonRpcEndpoint={jsonRpcEndpoint}
                theme={theme}
              />
              {/* <iframe
                src="https://app.uniswap.org/#/swap?outputCurrency=0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359"
                style={{height:"450px",width:"100%"}}
              /> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-outline-dark" onClick={props.onHide}>
            Return
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function UniSwapBid(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="mx-auto">
            Add funds
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row className="d-flex justify-content-center">
              <SwapWidget
                provider={provider}
                // jsonRpcEndpoint={jsonRpcEndpoint}
                theme={theme}
              />
              {/* <iframe
                src="https://app.uniswap.org/#/swap?outputCurrency=0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359"
                style={{height:"450px",width:"100%"}}
              /> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-outline-dark" onClick={props.onHide}>
            Return
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ButtonSwitch(props) {
    switch (buttonState) {
      case "sell":
        return (
          <Button
            variant="dark"
            size="medium"
            onClick={() => {
              navigate(
                `/assets/${nft.asset_contract.address}/${nft.token_id}/sell`
              );
            }}
          >
            Sell
          </Button>
        );
      case "selldisabled":
        return (
          <Button
            disabled
            variant="dark"
            size="medium"
            onClick={() => {
              navigate(
                `/assets/${nft.asset_contract.address}/${nft.token_id}/sell`
              );
            }}
          >
            Sell
          </Button>
        );
      case "notEnglish":
        return (
          <>
            <Button
              className="m-1"
              variant="dark"
              size="medium"
              onClick={() => {
                handleShow();
                // navigate(`/assets/${nft.asset_contract.address}/${nft.token_id}/makeoffer`);
              }}
            >
              Make Offer
            </Button>

            <Button
              className="m-1"
              variant="dark"
              size="medium"
              onClick={() => {
                handlebuyingalertShow();
                // buyingnft()
                // navigate(`/assets/${nft.asset_contract.address}/${nft.token_id}/makeoffer`);
              }}
            >
              Buy Now
            </Button>
          </>
        );
      case "English":
        return (
          <div className="">
            <div className="text-secondary d-flex flex-row align-items-center  rounded m-1">
              <i className="fa fa-clock-o m-1 "></i>
              <small>
                {" "}
                {listingendDate && (
                  <p className=" mb-0 ">
                    {" "}
                    Sale ends {new Date(listingendDate).toString()}
                  </p>
                )}
              </small>
            </div>
            <div className=" rounded m-1">
              <div className="text-muted m-2 mb-0">Minimum bid</div>

              <div>
                <div class="form-group d-flex justify-content-left">
                  <div className="d-flex mt-1 p-2">
                    <img
                      style={{ height: 40, padding: 5 }}
                      src="https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg"
                      alt="ETH logo"
                    />
                    <div className="m-2">
                      {" "}
                      <h4>
                        {listing &&
                          Web3.utils.fromWei(listing[0].base_price, "ether")}
                      </h4>
                    </div>
                    {listing && (
                      <div className="m-2 text-muted">
                        {" "}
                        (${" "}
                        {Math.round(
                          Web3.utils.fromWei(listing[0].base_price, "ether") *
                            usdrate *
                            100
                        ) / 100}
                        )
                      </div>
                    )}
                    <div>
                      <i className="fa fa-arrow-circle-o-up"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ml-2 d-flex flex-row align-items-end">
                <Button
                  variant="dark"
                  size="medium"
                  onClick={() => {
                    handlebiddingalertShow();
                    // navigate(`/assets/${nft.asset_contract.address}/${nft.token_id}/makeoffer`);
                  }}
                >
                  <div className="d-flex flex-row">
                    <div className="m-2">
                      <AccountBalanceWalletOutlinedIcon
                        style={{ color: "DarkGray" }}
                      />
                    </div>
                    <div className="m-2"> Place Bid </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        );
      case "nft":
        return (
          <Button
            variant="dark"
            size="medium"
            onClick={() => {
              handleShow();
              // navigate(`/assets/${nft.asset_contract.address}/${nft.token_id}/makeoffer`);
            }}
          >
            Make Offer
          </Button>
        );
      default:
        return null;
    }
  }

  const buttonConditionChecking = async () => {
    try {
      if (listing && ownership && ownership === true && listing.length == 0) {
        setButtonState("sell");
      } else if (
        listing &&
        ownership &&
        ownership === true &&
        listing.length >= 1
      ) {
        setButtonState("selldisabled");
      } else if (listingZero == "0x0000000000000000000000000000000000000000") {
        setButtonState("notEnglish");
      } else if (listingZero == "0xc778417e063141139fce010982780140aa0cd5ab") {
        setButtonState("English");
      } else {
        setButtonState("nft");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {nft &&
        (loading ? (
          <Loader />
        ) : error ? (
          <Error error="Something went wrong!" />
        ) : (
          <div>
            <section className="py-5">
              <div className="container px-4 px-lg-5 my-5">
                <div className="row gx-4 gx-lg-5 align-items-center">
                  <div className="col-md-6">
                    <img
                      className="card-img-top mb-5 mb-md-0"
                      src={nft.image_url}
                      alt="..."
                    />
                  </div>
                  <div className="col-md-6">
                    {nft.collection.name && (
                      <div className="small mb-1">{nft.collection.name}</div>
                    )}
                    {nft.name && (
                      <h1 className="display-5 fw-bolder">{nft.name}</h1>
                    )}
                    <div className="fs-5 mb-5">
                      {/* <span class="text-decoration-line-through">$45.00</span> */}
                      <span>
                        {/* <small>$200</small><br/> */}
                        <small>Owned By</small>
                        {ownership &&
                        nft.top_ownerships[0].owner.address &&
                        ownership === true ? (
                          <small className="text-secondary"> You</small>
                        ) : (
                          <small className="text-secondary">
                            {" "}
                            {nft.top_ownerships[0].owner.address}
                          </small>
                        )}
                      </span>
                    </div>
                    {nft.asset_contract.address &&
                      nft.token_id &&
                      nft.asset_contract.schema_name && (
                        <p className="small text-break">
                          Contract Address: {nft.asset_contract.address}
                          <br />
                          Token ID: {nft.token_id}
                          <br />
                          Token Standard: {nft.asset_contract.schema_name}
                          <br />
                          {/* <li>Blockchain: {nft.asset_contract.address}</li> */}
                        </p>
                      )}
                    <div className="d-flex">


                      {buttonState && listing && <ButtonSwitch />}

                      {/* <Button
            disabled
            variant="dark"
            onClick={() => {
              buyingnft();
              setBuyingTrueorFalse(true);
              handlebuyingClose();
            }}
          >
            Pay with Ethereum
          </Button>

          <Button
            variant="dark"
            onClick={() => {
              // buyingnft();
              // setBuyingTrueorFalse(true);
              
              handlebuyingClose();
              setcardBuying(true)
            }}
          >
            Card payment
          </Button> */}


                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="py-5 bg-light">
              <div className="container px-4 px-lg-5 mt-5">
                {/* <h2 class="fw-bolder mb-4">Related products</h2> */}
                <div>
                  <h8>
                    <b>Listings</b>
                  </h8>
                  {/* <hr /> */}
                  <div className="col mb-5">
                    <div className="card h-100">
                      {listing && listing.length == 0 ? null : (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <td>Price</td>
                              <td>USD Price</td>
                              <td>Expiration</td>
                              <td>From</td>
                            </tr>
                          </thead>
                          <tbody>
                            {listing &&
                              listing.map((listitem) => (
                                <tr className="text-break ">
                                  <td>
                                    <small>
                                      {listitem.payment_token_contract.symbol ==
                                      "WETH" ? (
                                        <img
                                          style={{ height: 30, padding: 5 }}
                                          src="https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg"
                                          alt="ETH logo"
                                        />
                                      ) : (
                                        <img
                                          style={{ height: 30, padding: 5 }}
                                          src={ethlogo}
                                          alt="ETH logo"
                                        />
                                      )}{" "}
                                      {listitem.payment_token_contract.symbol}{" "}
                                      {Web3.utils.fromWei(
                                        listitem.base_price,
                                        "ether"
                                      )}
                                    </small>
                                  </td>
                                  <td>
                                    <small>
                                      ${" "}
                                      {Math.round(
                                        Web3.utils.fromWei(
                                          listitem.base_price,
                                          "ether"
                                        ) *
                                          usdrate *
                                          100
                                      ) / 100}
                                    </small>
                                  </td>
                                  <td>
                                    <small>
                                      {new Date(listitem.closing_date)
                                        .toISOString()
                                        .substring(0, 10)}
                                    </small>
                                  </td>
                                  <td>
                                    <small>{listitem.maker.address}</small>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                      {/* {listing && } */}
                    </div>
                  </div>
                </div>

                <div>
                  <h8>
                    <b>Offers</b>
                  </h8>
                  {/* <hr /> */}
                  <div className="col mb-5">
                    <div className="card h-100">
                      {offerOrders && (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <td>Price</td>
                              <td>USD Price</td>
                              <td>Expiration</td>
                              <td>From</td>
                            </tr>
                          </thead>
                          <tbody>
                            {offerOrders &&
                              offerOrders.map((offer) => (
                                <tr className="text-break ">
                                  <td>
                                    <small>
                                      {offer.payment_token_contract.symbol ==
                                      "WETH" ? (
                                        <img
                                          style={{ height: 30, padding: 5 }}
                                          src="https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg"
                                          alt="ETH logo"
                                        />
                                      ) : (
                                        <img
                                          style={{ height: 30, padding: 5 }}
                                          src={ethlogo}
                                          alt="ETH logo"
                                        />
                                      )}{" "}
                                      {offer.payment_token_contract.symbol}{" "}
                                      {Web3.utils.fromWei(
                                        offer.base_price,
                                        "ether"
                                      )}
                                    </small>
                                  </td>
                                  <td>
                                    <small>
                                      ${" "}
                                      {Math.round(
                                        Web3.utils.fromWei(
                                          offer.base_price,
                                          "ether"
                                        ) *
                                          usdrate *
                                          100
                                      ) / 100}
                                    </small>
                                  </td>
                                  <td>
                                    <small>
                                      {new Date(offer.closing_date)
                                        .toISOString()
                                        .substring(0, 10)}
                                    </small>
                                  </td>
                                  <td>
                                    <small>{offer.maker.address}</small>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-auto">
            You're going to make a offer to following Nft
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                {nft && (
                  <div className="row m-2">
                    <div className="row ">
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={nft.image_preview_url}
                        className="img-fluid m-3 rounded mx-auto "
                        alt="img"
                      />
                    </div>
                    <div className="col">
                      <div className="d-flex flex-column m-3 ">
                        <div>Collection Name</div>
                        {/* <div>Creator</div> */}
                        <div className="text-break ">Contact Address</div>
                        <div>''</div>
                        <div>Created Date</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column mx-auto m-3">
                        <div>{nft.collection.name}</div>
                        {/* <div>{nft.creator.user.username}</div> */}
                        <div className="text-break ">
                          {nft.asset_contract.address}
                        </div>
                        <div>{nft.collection.created_date}</div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                I understand that mkt has not reviewed this collection and
                blockchain transactions are irreversible.
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              handleClose();
              handleShowoffer();
              setError(null);
            }}
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showoffer}
        onHide={handleCloseoffer}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <i
            onClick={() => {
              handleCloseoffer();
              handleShow();
            }}
            className="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <Modal.Title className="m-auto">Make an offer</Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <br />
            <Row>
              <Form.Label htmlFor="inputPassword5">
                <small>
                  <b>Price</b>
                </small>
              </Form.Label>
              <Col className="m-0 p-0">
                <Form.Select
                  onChange={(event) => {
                    // console.log(event.target.value)
                    setErcTokenType(event.target.value);
                    console.log(ercTokenType);
                  }}
                >
                  <option value="ETH" disabled>
                    ETH
                  </option>
                  <option value="WETH">WETH</option>
                </Form.Select>
              </Col>
              <Col className="m-0 p-0">
                <Form.Control
                  type="number"
                  id="inputPassword5"
                  placeholder="Amount"
                  onChange={(e) => {
                    setEthAmount(e.target.value);
                  }}
                />
              </Col>
              <Col className="m-0 p-0" xs={6} md={4}>
                <Form.Control
                  disabled
                  type="number"
                  id="inputPassword5"
                  placeholder={
                    "$ " + Math.round(ethAmount * usdrate * 100) / 100
                  }
                />
                {wethBalance && (
                  <Form.Text
                    id="passwordHelpBlock"
                    muted
                    className="align-items-end"
                  >
                    Available {Math.round(wethBalance * 100) / 100}WETH
                  </Form.Text>
                )}
              </Col>
            </Row>

            {offerError && (
              <div className="alert alert-danger" role="alert">
                Sorry Make Offer Failed!
                {<h6>Error Details: {offerError}</h6>}
              </div>
            )}

            <br />
            <Row>
              <Form.Label htmlFor="inputPassword5">
                <small>
                  <b>Offer Expiration</b>
                </small>
              </Form.Label>
              <Col xs={6} md={4}>
                <Form.Select
                  onChange={(event) => {
                    setNoOfDays(event.target.value);
                  }}
                >
                  <option
                    value={Math.round(Date.now() / 1000 + 60 * 60 * 24 * 3)}
                  >
                    3 days
                  </option>
                  <option value={Math.round(Date.now() / 1000 + 60 * 60 * 24)}>
                    1 day
                  </option>
                  <option
                    value={Math.round(Date.now() / 1000 + 60 * 60 * 24 * 7)}
                  >
                    7 days
                  </option>
                  <option
                    value={Math.round(Date.now() / 1000 + 60 * 60 * 24 * 30)}
                  >
                    1 month
                  </option>
                  {/* <option>custom date</option> */}
                </Form.Select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />
          {ethAmount && ethAmount.length !== 0 && ethAmount >= 0 ? (
            <Button variant="dark" onClick={offerCall}>
              Make Offer
            </Button>
          ) : (
            <Button disabled variant="dark" onClick={offerCall}>
              Make Offer
            </Button>
          )}
          <Button
            variant="btn btn-outline-dark"
            onClick={() => setUniswapShow(true)}
          >
            Convert ETH
          </Button>
        </Modal.Footer>
      </Modal>

      <UniSwap
        show={uniswapShow}
        onHide={() => {
          setUniswapShow(false);
          handleShowoffer();
        }}
      />

      <UniSwapBid
        show={uniswapBidShow}
        onHide={() => {
          setUniswapBidShow(false);
          handlebiddingShow();
        }}
      />

      {/* <Bidding show={biddingShow}  onHide={() => {
        setBiddingShow(false)
        
      }} /> */}

      {offer && (
        <Modal show={offer} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="mx-auto">
              You offered Successfully!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {offer && (
                  <div className="row border border-dark m-2">
                    <div className="d-flex justify-content-center ">
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={offer.asset.imagePreviewUrl}
                        className="img-fluid m-3 bigimg"
                        alt="img"
                      />
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column mx-auto m-3">
                        <div>
                          <small>
                            <b>{offer.asset.collection.name}</b>
                          </small>
                        </div>
                        <div className="text-uppercase">
                          <b>{offer.asset.name}</b>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <div className="d-flex flex-column m-3 ">
                        <div>Price</div>
                        <div className="d-flex flex-row">
                          <div>
                            <img
                              style={{ height: 30, padding: 5 }}
                              src="https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg"
                              alt="ETH logo"
                            />
                          </div>
                          <div className="mt-1"> {ethAmount}</div>
                        </div>
                        <div>
                          ${Math.round(ethAmount * usdrate * 100) / 100}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <div>Now the offer can be view on NFT Detail page </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              onClick={() => {
                handleClose();
                window.location.reload(false);
              }}
            >
              close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* bidding alert */}

      <Modal
        show={biddingalert}
        onHide={handlebiddingalertClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-auto">
            You're going to Bid to following Nft
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                {nft && (
                  <div className="row m-2">
                    <div className="row ">
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={nft.image_preview_url}
                        className="img-fluid m-3 rounded mx-auto "
                        alt="img"
                      />
                    </div>
                    <div className="col">
                      <div className="d-flex flex-column m-3 ">
                        <div>Collection Name</div>
                        {/* <div>Creator</div> */}
                        <div className="text-break ">Contact Address</div>
                        <div>''</div>
                        <div>Created Date</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column mx-auto m-3">
                        <div>{nft.collection.name}</div>
                        {/* <div>{nft.creator.user.username}</div> */}
                        <div className="text-break ">
                          {nft.asset_contract.address}
                        </div>
                        <div>{nft.collection.created_date}</div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                I understand that mkt has not reviewed this collection and
                blockchain transactions are irreversible.
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              handlebiddingalertClose();
              handlebiddingShow();
              // setError(null)
            }}
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={bidding}
        onHide={handlebiddingClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <i
            onClick={() => {
              handlebiddingClose();
              handlebiddingalertShow();
            }}
            className="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <Modal.Title className="m-auto">Place a Bid</Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <br />
            <Row>
              <Form.Label htmlFor="inputPassword5">
                <small>
                  <b>Price</b>
                </small>
              </Form.Label>
              <Col className="m-0 p-0">
                <Form.Select
                  onChange={(event) => {
                    // console.log(event.target.value)
                    setErcTokenType(event.target.value);
                    console.log(ercTokenType);
                  }}
                >
                  <option value="ETH" disabled>
                    ETH
                  </option>
                  <option value="WETH">WETH</option>
                </Form.Select>
              </Col>
              <Col className="m-0 p-0">
                <Form.Control
                  type="number"
                  id="inputPassword5"
                  placeholder={
                    listing[0] &&
                    Web3.utils.fromWei(listing[0].base_price, "ether")
                  }
                  onChange={(e) => {
                    setEthAmount(e.target.value);
                  }}
                />
              </Col>
              <Col className="m-0 p-0" xs={6} md={4}>
                <Form.Control
                  disabled
                  type="number"
                  id="inputPassword5"
                  placeholder={
                    "$ " + Math.round(ethAmount * usdrate * 100) / 100
                  }
                />
                {wethBalance && (
                  <Form.Text
                    id="passwordHelpBlock"
                    muted
                    className="align-items-end"
                  >
                    Available {Math.round(wethBalance * 100) / 100}WETH
                  </Form.Text>
                )}
              </Col>
              {listing[0] && wethBalance && ethAmount > wethBalance ? (
                <Form.Text
                  id="passwordHelpBlock"
                  className="align-items-end text-danger"
                >
                  Not enough WETH to place bid
                </Form.Text>
              ) : null}
              {listing[0] &&
              ethAmount < Web3.utils.fromWei(listing[0].base_price, "ether") ? (
                <Form.Text
                  id="passwordHelpBlock"
                  className="align-items-end text-danger"
                >
                  Offer must be at least the minimum price per unit of{" "}
                  {Web3.utils.fromWei(listing[0].base_price, "ether")} WETH
                </Form.Text>
              ) : null}
            </Row>

            {offerError && (
              <div className="alert alert-danger" role="alert">
                Sorry Make Bid Failed!
                {<h6>Error Details: {offerError}</h6>}
              </div>
            )}

            <br />
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />
          {ethAmount &&
          wethBalance &&
          ethAmount.length !== 0 &&
          ethAmount >= 0 ? (
            <Button variant="dark" onClick={offerCall}>
              Place Bid
            </Button>
          ) : (
            <Button disabled variant="dark" onClick={biddingcall}>
              Place Bid
            </Button>
          )}
          <Button
            variant="btn btn-outline-dark"
            onClick={() => setUniswapBidShow(true)}
          >
            Convert ETH
          </Button>
        </Modal.Footer>
      </Modal>

      {/* buying alert */}
      <Modal
        show={buyingalert}
        onHide={handlebuyingalertClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="m-auto">
            You're going to Buy following Nft
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                {nft && (
                  <div className="row m-2">
                    <div className="row ">
                      <img
                        style={{ height: "200px", width: "200px" }}
                        src={nft.image_preview_url}
                        className="img-fluid m-3 rounded mx-auto "
                        alt="img"
                      />
                    </div>
                    <div className="col">
                      <div className="d-flex flex-column m-3 ">
                        <div>Collection Name</div>
                        {/* <div>Creator</div> */}
                        <div className="text-break ">Contact Address</div>
                        <div>''</div>
                        <div>Created Date</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column mx-auto m-3">
                        <div>{nft.collection.name}</div>
                        {/* <div>{nft.creator.user.username}</div> */}
                        <div className="text-break ">
                          {nft.asset_contract.address}
                        </div>
                        <div>{nft.collection.created_date}</div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                I understand that mkt has not reviewed this collection and
                blockchain transactions are irreversible.
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              handlebuyingalertClose();
              handlebuyingShow();
              // setError(null)
            }}
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={buying}
        onHide={handlebuyingClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <i
            onClick={() => {
              handlebuyingClose();
              handlebuyingalertShow();
            }}
            className="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <Modal.Title className="m-auto">Complete Checkout</Modal.Title>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <br />
            <Row>
              <Col className="">
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b>Item</b>
                  </small>
                </Form.Label>
                {nft && (
                  <img
                    // style={{height:"200px",width:"200px"}}
                    src={nft.image_preview_url}
                    className="img-fluid rounded mx-auto "
                    alt="img"
                  />
                )}
              </Col>
              <Col>
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b></b>
                  </small>
                </Form.Label>
                {nft && (
                  <div>
                    <div>
                      <small>{nft.collection.name}</small>
                    </div>
                    <div className="text-break ">
                      <small>
                        <b>{nft.name}</b>
                      </small>
                    </div>
                  </div>
                )}
              </Col>
              <Col>
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b>Price</b>
                  </small>
                </Form.Label>
                <br />
                {nft && listing[0] && (
                  <div>
                    <div>
                      <small>
                        <img
                          style={{ height: 30, padding: 5 }}
                          src={ethlogo}
                          alt="ETH logo"
                        />
                        {Web3.utils.fromWei(listing[0].base_price, "ether")}
                      </small>
                    </div>
                    <div>
                      <small>
                        ${" "}
                        {Math.round(
                          Web3.utils.fromWei(listing[0].base_price, "ether") *
                            usdrate *
                            100
                        ) / 100}
                      </small>
                    </div>
                  </div>
                )}
                {ethBalance && (
                  <Form.Text
                    id="passwordHelpBlock"
                    muted
                    className="align-items-end"
                  >
                    Available {Math.round(ethBalance * 100) / 100}ETH
                  </Form.Text>
                )}
              </Col>
            </Row>

            {offerError && (
              <div className="alert alert-danger" role="alert">
                Sorry Buying Failed!
                {<h6>Error Details: {offerError}</h6>}
              </div>
            )}

            <br />
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />

          <Button
            variant="dark"
            onClick={() => {
              buyingnft();
              setBuyingTrueorFalse(true);
              handlebuyingClose();
            }}
          >
            Pay with Ethereum
          </Button>

          {/* <Button
            variant="dark"
            onClick={() => {
              // buyingnft();
              // setBuyingTrueorFalse(true);
              
              handlebuyingClose();
              setcardBuying(true)
            }}
          >
            Card payment
          </Button> */}

          {/* <Stripe/> */}

        </Modal.Footer>
      </Modal>

      {/* {offer && ( */}
      <Modal show={buyingTrueorFalse} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">Processing your request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div>
              Accept the transaction in your metamask wallet & await untill
              transaction proceed.
            </div>
            <Col>
              {offerError && (buyNftRes !== undefined || buyNftRes !== null)&& (
                <div className="alert alert-danger" role="alert">
                  Sorry Buying Failed!
                  {<h6>Error Details: {offerError}</h6>}
                </div>
              )}

              {(buyNftRes == undefined || buyNftRes == null) &&
              offerError == null ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>{" "}
                </div>
              ) : null}



              {buyNftRes && buyNftTxnHash && (
                <div className="alert alert-success" role="alert">
                  <div className="text-center">
                    {" "}
                    <b>You successfully bought {buyNftRes.asset.name}!</b>
                  </div>
                  <Row>
                    <Col className="">
                      <Form.Label htmlFor="inputPassword5">
                        <small>
                          <b>Item</b>
                        </small>
                      </Form.Label>

                      <img
                        // style={{height:"200px",width:"200px"}}
                        src={buyNftRes.asset.imagePreviewUrl}
                        className="img-fluid rounded mx-auto "
                        alt="img"
                      />
                    </Col>
                    <Col>
                      <Form.Label htmlFor="inputPassword5">
                        <small>
                          <b></b>
                        </small>
                      </Form.Label>

                      <div>
                        <div>
                          <small>{buyNftRes.asset.collection.name}</small>
                        </div>
                        <div className="text-break ">
                          <small>
                            <b>{buyNftRes.asset.name}</b>
                          </small>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="text-break ">
                      <small>
                        <b>Now this Nft Owned by You!!</b>
                      </small>
                      <br />
                      <small>
                        <b>TransactionHash: {buyNftTxnHash}</b>
                      </small>
                    </div>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              handleClose();
              window.location.reload(false);
            }}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>
      

    {/* card payment for buying */}
    <Modal
        show={cardbuying}
        onHide={handlecardbuyingClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
    <Modal.Header closeButton>
      <i
        onClick={() => {
          handlecardbuyingClose();
          handlebuyingShow();
        }}
        className="fa fa-arrow-left"
        aria-hidden="true"
      ></i>
      <Modal.Title className="m-auto">Card  Checkout</Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
          <Container>
            <br />
            <Row>
              <Col className="">
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b>Item</b>
                  </small>
                </Form.Label>
                {nft && (
                  <img
                    // style={{height:"200px",width:"200px"}}
                    src={nft.image_preview_url}
                    className="img-fluid rounded mx-auto "
                    alt="img"
                  />
                )}
              </Col>
              <Col>
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b></b>
                  </small>
                </Form.Label>
                {nft && (
                  <div>
                    <div>
                      <small>{nft.collection.name}</small>
                    </div>
                    <div className="text-break ">
                      <small>
                        <b>{nft.name}</b>
                      </small>
                    </div>
                  </div>
                )}
              </Col>
              <Col>
                <Form.Label htmlFor="inputPassword5">
                  <small>
                    <b>Price</b>
                  </small>
                </Form.Label>
                <br />
                {/* <small><b>$200</b></small>
                <small><p>Estimated gas fees $30</p></small> */}
                {nft && listing[0] && (
                  <div>
                    <div>
                      <small>
                        <img
                          style={{ height: 30, padding: 5 }}
                          src={ethlogo}
                          alt="ETH logo"
                        />
                        {Web3.utils.fromWei(listing[0].base_price, "ether")}
                      </small>
                    </div>
                    <div>
                      <small>
                        ${" "}
                        {Math.round(
                          Web3.utils.fromWei(listing[0].base_price, "ether") *
                            usdrate *
                            100
                        ) / 100}
                      </small>
                    </div>
                  </div>
                )}
                {ethBalance && (
                  <Form.Text
                    id="passwordHelpBlock"
                    muted
                    className="align-items-end"
                  >
                    Available {Math.round(ethBalance * 100) / 100}ETH
                  </Form.Text>
                )}
              </Col>
            </Row>

            {/* {offerError && (
              <div className="alert alert-danger" role="alert">
                Sorry Buying Failed!
                {<h6>Error Details: {offerError}</h6>}
              </div>
            )} */}

            <br />
            <hr/>
            <Stripe blockchain={blockchain} accountAddress={accountAddress} contractAddress={contractAddress} token_Id={token_Id}/>
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />



        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default NftDetailPage;
