import React from 'react'


const Footer = () => {
  return (
      
        <div className='mt-5 position-relative-bottom'>
            <footer className="py-3 bg-dark ">
                <div className="container"><p className="m-0 text-center text-white">Copyright &copy; nFinityMark 2022</p></div>
             </footer> 
         </div>   
    
  )
}

export default Footer