import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./config.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
const env = "prod";
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="nftmkt.us.auth0.com"
      clientId={
        config.SERVER_IP != null && process.env.REACT_APP_ENV == "stage"
          ? "sfWoUYH6v9ncDJPY5JpLClpX1c8ZekGp"
          : "rIC5oMxUa7lUK0nuVLe7DxjUw6QyuCFR"
      }
      redirectUri={window.location.origin}
      audience="This is MKT Identifier"
      scope="openid profile email"
    >
      <BrowserRouter>
        <Provider store={store}>
          {process.env.REACT_APP_ENV === "prod"
            ? (console.log = () => {})
            : null}
          <App />
        </Provider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);
