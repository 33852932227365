import React, { useState, useEffect } from "react";
import NftCard from "../components/NftCard";
import config from "../config.json";
// import Web3 from "web3";
import Loader from "../components/Loader";
import { useNavigate, useParams } from "react-router-dom";

var url;
// var current_node;
var openSeaDomain = config.OPENSEA_MAIN;
var X_API_KEY = config.MAIN_X_API_KEY;

const NftbyWallet = ({blockchain,accountAddress}) => {

    
    const [loading, setLoading] = useState(true)
    const [nfts, setNfts] = useState();
    const [walletAvailable, setWalletAvailable] = useState()

    let { walletAddress } = useParams();
  
    const nftsbywalletAddress = async () => {

    

    //   window.ethereum
    //   .request({ method: "eth_requestAccounts" })
    //   .then(async(result) => {
            
    //     if ( result[0] == undefined) {
    //             // console.log(" not connected");
    //             setWalletAvailable(false)
    //             console.log(walletAvailable)
    //         } else{
    //           // console.log("connected");
    //           setWalletAvailable(true)
    //           console.log(walletAvailable)
    //         }
        
    //   }).then(()=> {

      console.log(blockchain,accountAddress)

      if (blockchain != config.main_network_node) {
        openSeaDomain = config.OPENSEA_TEST;
        X_API_KEY = config.TEST_X_API_KEY;
      }

      const options = {
        method: "GET",
        headers: { Accept: "application/json", "X-API-KEY": X_API_KEY },
      };
      url = `${openSeaDomain}assets?owner=${walletAddress}&order_direction=desc&offset=0&limit=20`;

  
      
      fetch(url, options)
        .then((response) => response.json())
        .then((response) => {
          console.log(response.assets);
          setNfts(response.assets);
          setLoading(false)
        })
        .catch((err) => {
          console.log(err.message)
          
        }
        );
    //   })
    };


  
    useEffect(() => {
      nftsbywalletAddress();
    }, []);
    
 
  
    return (<>
        <div >
        {walletAvailable == false && <div className="alert alert-dark  mt-5 pt-5" role="alert">
            connect your metamask wallet first!  .  
                 <span onClick={()=>window.location.reload()}><i class="fa fa-refresh" aria-hidden="true"></i></span>
            
           
          </div>}
          <div className="row justify-content-center ml-2 mr-2 mt-5 pt-5">
          {
            nfts && 
              (loading ? (
              <Loader />
              )  : (
              nfts.map((nft) => {
                return (
                  <div key={nft.id} className="col-md-3 m-2 p-0 bg-white rounded">
                    <NftCard nft={nft} />
                  </div>
                );
              })
            ))
          }
          
        </div>
      </div>
      </>
    );
}

export default NftbyWallet