import React, { useState, useEffect } from "react";
import suggestions from "../assets/collections.json";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Modal, Button, Row, Col, Alert, Form } from "react-bootstrap";
import { useMediaQuery, useTheme } from "@mui/material";
import Web3 from "web3";
import nmlogo from "../assets/nfinitymark.png";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  FormControl,
  Dropdown,
} from "react-bootstrap";

import { useAuth0 } from "@auth0/auth0-react";
import config from "../config.json";

var serverIp = "http://192.168.1.21:3001/";
console.log("typeof config.SERVER_IP  : ", typeof config.SERVER_IP);
// var is_fetched = false;
if (typeof config.SERVER_IP != "object") {
  serverIp = config.SERVER_IP; //"http://3.68.139.145:80/";  "https://www.eng.nfinitymark.com/"  "https://www.mint-staging1.nfinitymark.com/"
}

const NavBar = ({ blockchainN }) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [icon, setIcon] = useState(<AccountBalanceWalletOutlinedIcon />);
  const [provider, setProvider] = useState();
  const [searchPlaceholder, setSearchPlaceholder] = useState();
  const [searchValue, setSearchValue] = useState();
  const [nftcollections, setNftcollections] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    // setErrorMessage(null)
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [signUpshow, setsignUpshow] = useState(false);
  const handlesignupClose = () => {
    // setErrorMessage(null)
    setsignUpshow(false);
  };
  const handlesignupshow = () => setsignUpshow(true);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const validwalletAddress = new RegExp("^0x[a-fA-F0-9]{40}$");

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  // const userverifiacation = async () => {
  //   if (user.email_verified == false) {
  //   }
  // };

  useEffect(() => {
    handleButtonState();
  }, []);

  useEffect(() => {
    retrievecollectionhandler();
  }, [blockchainN]);

  useEffect(() => {
    handleButtonState();
    setProvider(new Web3(Web3.givenProvider));
  }, [defaultAccount, connButtonText]);

  useEffect(() => {
    // console.log(user["http://localhost/wallet_address"]);
    console.log(user);

    let stringJson = JSON.stringify(user);
    console.log(stringJson);
    if (stringJson != null) {
      let splitObj = stringJson.split(":");

      let walletAddress = user["http://localhost/wallet_address"]; //splitObj[2].split('"')[1];
      console.log("walletAddress :", walletAddress);
      console.log(walletAddress);
      // protectedApi();
    }
  }, [user]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }
  // const reset = () => {
  //   setSearchPlaceholder("Search accounts,contracts")
  // };

  const retrievecollectionhandler = async () => {
    fetch(serverIp + `mkt/fetchCollections?network=all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   "contractAddress":contractAddress,
      //   "tokenId":token_Id,
      //   "nftPrice":updatingPrice
      // })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          console.log(response);
          setNftcollections(response);
        }
      })
      .catch((err) => console.error(err));
  };

  const searchbarhandler = async () => {
    console.log(searchValue);
    const web = await Web3.utils.isAddress(searchValue);
    console.log(web);

    if (web == true) {
      //   if (validwalletAddress.test(searchValue)) {
      //     console.log('this is wallet address')
      //  }else{
      //   console.log('this is contract adress')
      //  }

      // console.log("valid address")

      const options = { method: "GET" };

      fetch(
        `https://testnets-api.opensea.io/api/v1/asset_contract/${searchValue}`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (
            response.address == searchValue ||
            response.address == searchValue.toLowerCase
          ) {
            console.log(response.collection.slug);
            navigate(`/collection/${response.collection.slug}`);
            window.location.reload();
          }
        });
      fetch(
        `https://testnets-api.opensea.io/api/v1/assets?owner=${searchValue}&order_direction=desc&offset=40&limit=20&include_orders=false`,
        options
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response)
          if (response.length !== 0) {
            console.log(response);
            navigate(`/account/${searchValue}`);
            window.location.reload();
          }
        })
        .catch((err) => console.error(err));
      // .catch(err => console.error(err));
    } else {
      console.log("please insert valid search value");
    }
  };

  // const protectedApi = async () => {
  //   const token = await getAccessTokenSilently();
  //   console.log(`Token : ${token}`);
  // };

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          setConnButtonText("Wallet Connected");
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  const handleButtonState = async () => {
    try {
      if (window.ethereum.selectedAddress == null) {
        setIcon(
          <AccountBalanceWalletOutlinedIcon
            style={{ color: "DarkGray" }}
            onClick={connectWalletHandler}
          />
        );
      } else {
        setIcon(<HowToRegOutlinedIcon style={{ color: "DarkGray" }} />);
      }
    } catch (err) {
      console.log("Error code 615", err);
    }
  };

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto dropToggle"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        style={{ height: 68 }}
      >
        <Container>
          <Navbar.Brand>
            <Link to={`/`} className="text-decoration-none">
              <img
                style={{ height: 60, padding: 5 }}
                src={nmlogo}
                className="App-logo"
                alt="Nfinitymark logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link className="m-4" border="secondary">
                <Form className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Search accounts,contracts"
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="me-2"
                    aria-label="Search"
                    size="sm"
                    value={searchValue}
                  />
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    type="submit"
                    onClick={(e) => {
                      setSearchValue("");
                      searchbarhandler();
                    }}
                  >
                    Search
                  </Button>
                </Form>
              </Nav.Link>

              <Nav.Link>
                <Dropdown className="m-4 drop">
                  <Dropdown.Toggle
                    className="dropToggle"
                    id="dropdown-autoclose-true"
                    variant="outline-secondary"
                    size="sm"
                  >
                    Search Collection
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu} className="dropMenu">
                    {nftcollections &&
                      console.log(nftcollections.dbObj.rinkeby)}
                    {nftcollections &&
                      console.log(nftcollections.dbObj.mainnet)}
                    {blockchainN &&
                      nftcollections &&
                      (blockchainN === "main"
                        ? nftcollections.dbObj.mainnet.map((item) => {
                            // console.log(item)
                            return (
                              <Dropdown.Item
                                className="dropdownlist"
                                eventKey={item}
                                key={item}
                                onClick={() => {
                                  navigate(`/collection/${item}`);
                                  window.location.reload();
                                }}
                              >
                                {item}
                              </Dropdown.Item>
                            );
                          })
                        : nftcollections.dbObj.rinkeby.map((item) => {
                            // console.log(item)
                            return (
                              <Dropdown.Item
                                className="dropdownlist"
                                eventKey={item}
                                key={item}
                                onClick={() => {
                                  navigate(`/collection/${item}`);
                                  window.location.reload();
                                }}
                              >
                                {item}
                              </Dropdown.Item>
                            );
                          }))}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link>

              <Nav.Link className="m-4 ">
                {!isAuthenticated ? (
                  <a
                    className="btn btn-outline-secondary align-middle "
                    onClick={loginWithPopup}
                    style={{
                      color: "DarkGray",
                      fontFamily: "museo500",
                      fontSize: "12px",
                    }}
                  >
                    {!isAuthenticated ? "Login" : "Logout"}
                  </a>
                ) : (
                  <a
                    className="btn btn-outline-secondary align-middle"
                    onClick={logout}
                    style={{
                      color: "DarkGray",
                      fontFamily: "museo500",
                      fontSize: "12px",
                    }}
                  >
                    {isAuthenticated ? "Logout" : "Login"}
                  </a>
                )}
                {/* <Link  to="/account"  className="text-decoration-none">
                <span style={{ color: "white", fontFamily: "museo500" }}>
                    <AccountCircleOutlinedIcon  style={{ color: "DarkGray" }}/>  
                </span>
              </Link> */}
              </Nav.Link>

              <Nav.Link className="m-4">
                <Link to="/account" className="text-decoration-none">
                  <span style={{ color: "white", fontFamily: "museo500" }}>
                    <AccountCircleOutlinedIcon style={{ color: "DarkGray" }} />
                  </span>
                </Link>
              </Nav.Link>

              <Nav.Link eventKey={2} className="m-4 ">
                <span
                  id="connect"
                  style={{ color: "muted", fontFamily: "museo500" }}
                >
                  {icon}
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Login */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="m-auto">Sign In</h3>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                <form>
                  <div className="mb-3">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="mb-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                  <p className="forgot-password text-right">
                    Forgot <a>password?</a>
                  </p>
                </form>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col>
                I understand that mkt has not reviewed this collection and
                blockchain transactions are irreversible.
              </Col>
            </Row> */}
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <p className="forgot-password text-right">
            create an account{" "}
            <a
              onClick={() => {
                handlesignupshow();
                handleClose();
              }}
              style={{ color: "blue" }}
            >
              sign up?
            </a>
          </p>
          {/* <Button
            variant="dark"
            onClick={() => {
              // handleClose();
              // handleShowoffer();
              // setError(null);
            }}
          >
            Understood
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* SignUp */}
      <Modal
        show={signUpshow}
        onHide={handlesignupClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h3 className="m-auto">Sign Up</h3>
        </Modal.Header>

        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col>
                <form>
                  <div className="mb-3">
                    <label>First name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                      Sign Up
                    </button>
                  </div>
                  <p className="forgot-password text-right">
                    Already registered{" "}
                    <a
                      onClick={() => {
                        handleShow();
                        handlesignupClose();
                      }}
                      style={{ color: "blue" }}
                    >
                      sign in?
                    </a>
                  </p>
                </form>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col>
                I understand that mkt has not reviewed this collection and
                blockchain transactions are irreversible.
              </Col>
            </Row> */}
          </Container>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button
            variant="dark"
            onClick={() => {
              // handleClose();
              // handleShowoffer();
              // setError(null);
            }}
          >
            Understood
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default NavBar;
