import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();

  useEffect(() => {
    // console.log(user["http://localhost/wallet_address"]);
    if (isAuthenticated) {
      console.log(user);

      let stringJson = JSON.stringify(user);
      console.log(stringJson);
      if (stringJson != null) {
        let splitObj = stringJson.split(":");

        let walletAddress = splitObj[2].split('"')[1];
        console.log(walletAddress);
      }
    }
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
      <div>
        Hello {user.name}{" "}
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
    );
  } else {
    return <button onClick={loginWithRedirect}>Log in</button>;
  }
};

export default Login;
