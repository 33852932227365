import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OpenSeaPort } from "opensea-js";
import { OrderSide } from "opensea-js/lib/types";
import Web3 from "web3";
import { getsinglenft } from "../redux/fetchsinglenft/fetchsinglenftActions";
import Loader from "../components/Loader";
import Error from "../components/Error";
import config from "../config.json";
import {
  Modal,
  Button,
  Row,
  Col,
  Alert,
  Container,
  Form,
} from "react-bootstrap";

import Stripe from "../components/Stripe";
import { useAuth0 } from "@auth0/auth0-react";
import swal from "sweetalert";
import Countdown, { CountdownApi } from "react-countdown";

var url;
var openSeaDomain = config.OPENSEA_MAIN;
var X_API_KEY = config.MAIN_X_API_KEY;
var web3 = new Web3(Web3.givenProvider);
const provider = Web3.givenProvider;
var serverIp = "http://192.168.1.21:3001/";
console.log("typeof config.SERVER_IP  : ", typeof config.SERVER_IP);
// var is_fetched = false;
if (typeof config.SERVER_IP != "object") {
  serverIp = config.SERVER_IP; //"http://3.68.139.145:80/";  "https://www.eng.nfinitymark.com/"  "https://www.mint-staging1.nfinitymark.com/"
}

const MktNftDetailPage = ({ blockchain, accountAddress }) => {
  const [ownership, setOwnership] = useState(null);
  const [walletowner, setWalletowner] = useState();
  const [walletownerlowercase, setWalletownerlowercase] = useState();
  const [gasprice, setgasprice] = useState();

  const [offerError, setError] = useState();
  const [usdrate, setUsdrate] = useState();

  const [ethBalance, setEthBalance] = useState();
  const [seaport, setSeaport] = useState();

  const [mktbtnstatus, setmktbtnstatus] = useState();
  const [emailverificationneeded, setemailverificationneeded] = useState(false);
  const [nftStatus, setNftStatus] = useState();
  const [mktnftDetail, setmktnftDetail] = useState();
  const [updatingPrice, setupdatingPrice] = useState();
  const [nftPrice, setnftPrice] = useState();
  const [stripegas, setstripegas] = useState();
  const [priceUpdateResponse, setpriceUpdateResponse] = useState();
  const [totalstripecheckout, setTotalstripecheckout] = useState();
  const [checkoutPrice, setCheckoutPrice] = useState();
  const [stripeSuccessData, setStripeSuccessData] = useState(false);
  // const [clockRef, setClockRef] = useState();
  // const clockRef = Countdown.getApi();
  let countdownApi: CountdownApi | null = null;

  const clockRef = (countdown: Countdown | null): void => {
    if (countdown) {
      countdownApi = countdown.getApi();
    }
  };
  const handleStartClick = () => {
    countdownApi && countdownApi.start();
  };

  let { contractAddress, token_Id } = useParams();

  const stripesuccess = (stripeSuccessData) => {
    setStripeSuccessData(stripeSuccessData);
    console.log("stripeSuccessData : ", stripeSuccessData);

    if (stripeSuccessData) {
      countdownApi.stop();
      setNftStatus(false);
    }
  };

  const [cardbuying, setcardBuying] = useState(false);
  const handlecardbuyingClose = () => {
    setError(null);
    setcardBuying(false);
  };
  const handlecardbuyingShow = () => setcardBuying(true);

  const [buying, setBuying] = useState(false);
  const handlebuyingClose = () => {
    setError(null);
    setBuying(false);
  };
  const handlebuyingShow = () => setBuying(true);

  const [mktpriceUpdate, setmktpriceUpdate] = useState(false);
  const handlemktpriceUpdateClose = () => {
    setError(null);
    setmktpriceUpdate(false);
  };
  const handlemktpriceUpdateShow = () => setmktpriceUpdate(true);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const nftstate = useSelector((state) => state.getsinglenftReducer);
  const { nft, loading, error } = nftstate;
  const [accountAddressn, setaccountAddress] = useState();

  const {
    isLoading,
    isAuthenticated,
    // error,
    user,
    loginWithRedirect,
    logout,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    // console.log(user["http://localhost/wallet_address"]);
    console.log(user);
    let stringJson = JSON.stringify(user);
    // console.log(stringJson);
    if (stringJson != null) {
      let splitObj = stringJson.split(":");
      let walletAddress = splitObj[2].split('"')[1];
      // console.log(walletAddress);
      setaccountAddress(walletAddress);
    }

    if (user != null && user.email_verified == false) {
      setemailverificationneeded(true);

      swal({
        title: "Verify your email address!",
        text: "Once cancel you will not be able to buy this Nft. for buy this nft verify your email address",
        icon: "warning",
        // buttons: true,
        dangerMode: true,
      });
    }

    // const web = Web3.utils.isAddress('0xbc5538d9eb46fb4adb5d1e111144a66f26f2d43a')
    // console.log(web)
    // console.log(isAuthenticated,user)
  }, [user]);

  useEffect(() => {
    dispatch(getsinglenft(contractAddress, token_Id));
    walletAddress();
    checkingOwnership();
    usdconversion();
    gettingGasFee();

    // nftStatusHandler()
    // buttonstatushandler()
  }, []);

  useEffect(() => {
    nftStatusHandler();
  }, []);

  // useEffect(() => {
  //   // updatepriceButtonView()
  // }, []);

  useEffect(() => {
    buttonstatushandler();
  }, [walletowner, isAuthenticated, user]);

  // useEffect(() => {
  //   if (clockRef != null) clockRef.current.start();

  // }, [nftStatus]);

  useEffect(() => {
    checkingOwnership();
  }, [walletowner, nft]);

  useEffect(() => {
    createSdkObj();
  }, [ownership]);

  // Random component
  const Completionist = () => {
    // <span>You are good to go!</span>
    handlecardbuyingClose();
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div
          className="p-3 mb-2 bg-success text-white rounded"
          style={{ fontSize: "20px", width: "90px" }}
        >
          <span>
            {hours}:{minutes}:{seconds}
          </span>
        </div>
      );
    }
  };

  const createSdkObj = async () => {
    if (blockchain != config.main_network_node) {
      openSeaDomain = config.OPENSEA_TEST;
      X_API_KEY = config.TEST_X_API_KEY;
    }

    let seaportObj = new OpenSeaPort(provider, {
      networkName: blockchain,
      apiKey: X_API_KEY,
    });
    setSeaport(seaportObj);
  };

  const usdconversion = () => {
    // console.log(blockchain, accountAddress);
    var usdValue;
    try {
      const url = "https://api.binance.com/api/v3/avgPrice?symbol=ETHUSDT";
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          usdValue = response.price;
          setUsdrate(usdValue);
          // console.log("ETH/USD' : ", usdValue);
        })
        .catch((err) => console.error(err));
    } catch (err) {}
  };

  const walletAddress = async () => {
    window.ethereum
      .request({ method: "eth_accounts" })
      .then(async (result) => {
        let wallet = result[0];
        let walletLow = wallet.toLowerCase();
        setWalletownerlowercase(walletLow);
        let balance = await web3.eth.getBalance(result[0]);
        // console.log("wallet is : ", wallet);
        // console.log("balance is : ", balance);
        setWalletowner(wallet);

        const etherValue = Web3.utils.fromWei(balance, "ether");
        console.log("base_price is : ", etherValue);
        setEthBalance(etherValue);
        // console.log("My wallet Add :", wallet);
        // console.log("My wallet Wei ETH Bal :", balance);
        // console.log("My wallet ETH Bal :", etherValue);
      })
      .catch((error) => {
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ : ", error);
      });
  };

  const checkingOwnership = () => {
    if (nft && walletowner) {
      if (nft.top_ownerships[0].owner.address === walletowner) {
        setOwnership(true);
      } else {
        setOwnership(false);
      }
    }
  };

  const gettingGasFee = async () => {
    const token = await getAccessTokenSilently();
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    var url = serverIp + "mkt/gasFee";
    fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.success == true) {
          // console.log(response.data.gasLimit);
          let gasfee = response.data.gasLimit;
          // let weigas = Web3.utils.toWei(gasfee , 'gwei')
          let ethgas = Web3.utils.fromWei(
            Web3.utils.toWei(gasfee, "gwei"),
            "ether"
          );
          // console.log(weigas)
          // console.log(ethgas)
          setgasprice(ethgas);
          setstripegas(Math.round(ethgas * usdrate * 100) / 100);
        } else {
          setgasprice(1);
        }
      })
      .catch((err) => console.error(err));
  };

  const buttonstatushandler = async () => {
    try {
      if (
        walletowner != null ||
        (isAuthenticated == true && user.email_verified == true)
      ) {
        setmktbtnstatus("active");
      } else if (
        walletowner != null ||
        walletowner != undefined ||
        (isAuthenticated == true && user.email_verified == false)
      ) {
        setmktbtnstatus("notverifiedemail");
      } else {
        setmktbtnstatus("disable");
      }
    } catch (error) {}
  };

  const nftStatusHandler = async () => {
    const token = await getAccessTokenSilently();
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    var url =
      serverIp +
      `mint/getNftStatus?tokenID=${token_Id}&contractAddress=${contractAddress}`;
    fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        if (response.success !== null) {
          console.log(response);
          // countdownApi && countdownApi.start();
          setNftStatus(response.success);
          setnftPrice(response.data.price);
          setmktnftDetail(response);
        }
      })
      .catch((err) => console.error(err));
  };

  const priceupdateHandler = async () => {
    console.log(contractAddress, token_Id);
    if (
      updatingPrice !== 0 &&
      updatingPrice !== null &&
      updatingPrice != undefined
    ) {
      var url = serverIp + `mkt/addNftPrice`;
      const token = await getAccessTokenSilently();
      fetch(url, {
        method: "PUT",
        headers: {
          // Accept: "application/x-www-form-urlencoded",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          walletAddress: walletowner,
          contractAddress: contractAddress,
          tokenId: token_Id,
          nftPrice: updatingPrice,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response !== null) {
            console.log(response);
            setpriceUpdateResponse(response);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  function ButtonSwitch(props) {
    console.log(mktbtnstatus);
    switch (mktbtnstatus) {
      case "active":
        return (
          <>
            <Button
            className="m-1"
              disabled
              variant="dark"
              onClick={() => {
                // buyingnft();
                // setBuyingTrueorFalse(true);
                handlebuyingClose();
              }}
            >
              Pay with Ethereum
            </Button>

            <Button
            className="m-1"
              disabled={nftPrice == null ? true : false}
              variant="dark"
              onClick={() => {
                handlebuyingClose();
                handlecardbuyingShow();
                let totalNftPrice =
                  parseFloat(nftPrice) +
                  parseFloat(gasprice * usdrate) +
                  parseFloat(10);
                // console.log((totalNftPrice*100.)toFixed(0))
                setTotalstripecheckout((totalNftPrice * 100).toFixed(0));
                setCheckoutPrice(totalNftPrice.toFixed(2));
                // nftStatusHandler()
              }}
            >
              Card payment
            </Button>
          </>
        );
      case "notverifiedemail":
        return (
          <>
            <Button
            className="m-1"
              disabled
              variant="dark"
              onClick={() => {
                // buyingnft();
                // setBuyingTrueorFalse(true);
                handlebuyingClose();
              }}
            >
              Pay with Ethereum
            </Button>

            <Button
              disabled
              className="m-1"
              variant="dark"
              onClick={() => {
                handlebuyingClose();
                setcardBuying(true);
              }}
            >
              Card payment
            </Button>
          </>
        );
      case "disable":
        return (
          <>
            <Button
            className="m-1"
              disabled
              variant="dark"
              onClick={() => {
                // buyingnft();
                // setBuyingTrueorFalse(true);
                handlebuyingClose();
              }}
            >
              Pay with Ethereum
            </Button>

            <Button
            className="m-1"
              disabled
              variant="dark"
              onClick={() => {
                handlebuyingClose();
                setcardBuying(true);
              }}
            >
              Card payment
            </Button>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      {nft &&
        (loading ? (
          <Loader />
        ) : error ? (
          <Error error="Something went wrong!" />
        ) : (
          <div>
            <section className="py-5">
              <div 
              // className="d-flex flex-row justify-content-between m-0 p-0"
              >
                
              
              {(mktnftDetail && mktnftDetail.success == false) ||
              nftPrice == null ? (
                <div className="alert alert-danger m-0" role="alert">
                  Sorry! At this moment, This NFT is not Available to Buy
                </div>
              ) : null}
              </div>
            
              
              <div className="container px-4 px-lg-5 my-5">
                <div className="row gx-4 gx-lg-5 align-items-center">
                  <div className="col-md-6">
                    <img
                      className="card-img-top mb-5 mb-md-0"
                      src={nft.image_url}
                      alt="..."
                    />
                  </div>
                  <div className="col-md-6">
                    {nft.collection.name && (
                      <div className="small mb-1">{nft.collection.name}</div>
                    )}
                    {nft.name && (
                      <h1 className="display-5 fw-bolder">{nft.name}</h1>
                    )}
                    <div className="fs-5 mb-5">
                      {/* <span class="text-decoration-line-through">$45.00</span> */}
                      <span>
                        {nftPrice && console.log(nftPrice)}
                        <small>
                          {nftPrice !== null ? (
                            <b>${nftPrice}</b>
                          ) : (
                            <b>price is still not assign</b>
                          )}{" "}
                        </small>{" "}
                        <br />
                        {/* {walletowner && console.log(walletownerlowercase)} */}
                        {walletowner &&
                        isAuthenticated == true &&
                        user.email_verified == true &&
                        (walletowner ==
                          "0x85f69e35c2ef4816fe4c56a04ccc18a3ce7fd29b" ||
                          walletowner ==
                            "0x9c2f4Ad1d8aA678b8D5915BEB9E63684DA19d21f" ||
                          walletowner ==
                            "0xbe114df43384366024e69b9b795ef8319e1ce824") ? (
                          <Button
                            className="btn-sm"
                            variant="dark"
                            onClick={() => handlemktpriceUpdateShow()}
                          >
                            Update Price
                          </Button>
                        ) : (
                          console.log("wallet is not match update price")
                        )}
                        {/* <Button className="btn-sm" variant="dark" onClick={()=> handlemktpriceUpdateShow() }>Update Price</Button> */}
                        <br />
                        <small>Owned By</small>
                        {ownership &&
                        nft.top_ownerships[0].owner.address &&
                        ownership === true ? (
                          <small className="text-secondary"> You</small>
                        ) : (
                          <small className="text-secondary">
                            {" "}
                            {nft.top_ownerships[0].owner.address}
                          </small>
                        )}
                      </span>
                    </div>
                    {nft.asset_contract.address &&
                      nft.token_id &&
                      nft.asset_contract.schema_name && (
                        <p className="small text-break">
                          Contract Address: {nft.asset_contract.address}
                          <br />
                          Token ID: {nft.token_id}
                          <br />
                          Token Standard: {nft.asset_contract.schema_name}
                          <br />
                          {/* <li>Blockchain: {nft.asset_contract.address}</li> */}
                        </p>
                      )}
                    <div className="d-flex">
                      {mktbtnstatus && <ButtonSwitch />}
                      <div className="m-0">
                <Button variant="dark" className="m-1" onClick={() => navigate(-1)}>back</Button>
                </div>
                      {/* <Button
                        disabled
                        variant="dark"
                        onClick={() => {
                          // buyingnft();
                          // setBuyingTrueorFalse(true);
                          handlebuyingClose();
                        }}
                      >
                        Pay with Ethereum
                      </Button>

                      <Button
                        variant="dark"
                        onClick={() => {

                          handlebuyingClose();
                          setcardBuying(true);
                        }}
                      >
                        Card payment
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}

      {/* card payment for buying */}
      <Modal
        show={cardbuying}
        onHide={handlecardbuyingClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <i
            onClick={() => {
              handlecardbuyingClose();
              handlebuyingShow();
            }}
            className="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <Modal.Title className="m-auto">
            <div className="m-auto">Card Checkout</div>
            {nftStatus && nftStatus == true ? (
              <div
                className=" m-auto bg-success rounded text-white"
                style={{ fontSize: "14px" }}
              >
                <small>NFT is Available to Buy</small>
              </div>
            ) : (
              <div
                className="m-auto bg-warning rounded"
                style={{ fontSize: "14px" }}
              >
                <small>This Nft already in Buying process</small>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <br />

            <Row className="m-0">
              <Col className="">
                {nft && (
                  <img
                    // style={{height:"200px",width:"200px"}}
                    src={nft.image_preview_url}
                    className="img-fluid rounded mx-auto "
                    alt="img"
                  />
                )}
              </Col>
              <Col>
                {nft && (
                  <div>
                    <div>
                      <small>{nft.collection.name}</small>
                    </div>
                    <div className="text-break ">
                      <small>
                        <b>{nft.name}</b>
                      </small>
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  <table className="table table-borderless table-sm table-secondary">
                    <tbody>
                      <tr>
                        <th scope="row" colspan="2">
                          <small>NFT Price:</small>
                        </th>
                        <td>
                          <small>
                            {nftPrice !== null ? (
                              <b>${nftPrice}</b>
                            ) : (
                              <b>price is still not assign</b>
                            )}
                          </small>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" colspan="2">
                          <small>Est. Gas fee:</small>
                        </th>
                        <td>
                          <small>
                            $
                            {gasprice &&
                              Math.round(gasprice * usdrate * 100) / 100 + 10}
                          </small>
                        </td>
                      </tr>
                      <tr className="table-active">
                        <th scope="row" colspan="2">
                          <small>Total Amount:</small>
                        </th>
                        <td>
                          $
                          {gasprice &&
                            (
                              parseFloat(gasprice * usdrate * 100) / 100 +
                              parseFloat(10) +
                              parseFloat(nftPrice)
                            ).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {nftStatus && nftStatus == true ? (
                  <Countdown
                    ref={clockRef}
                    // autoStart={false}
                    date={Date.now() + 120000}
                    renderer={renderer}

                    // onStop={}
                  />
                ) : (
                  <></>
                )}
                <br />

                {/* {ethBalance && (
                  <Form.Text
                    id="passwordHelpBlock"
                    muted
                    className="align-items-end"
                  >
                    Available {Math.round(ethBalance * 100) / 100}ETH
                  </Form.Text>
                )} */}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="border rounded m-2 mb-0">
                  <table className="table table-borderless table-sm ">
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          colspan="2"
                          className="text-center align-middle"
                        >
                          <small>This NFT Transfer To :</small>
                        </th>
                        <td className="align-middle">
                          <small>{accountAddressn}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <small>
                      <b>NFT Transfer To :  </b>
                    </small> */}
                </div>
              </Col>
            </Row>
            {/* {offerError && (
              <div className="alert alert-danger" role="alert">
                Sorry Buying Failed!
                {<h6>Error Details: {offerError}</h6>}
              </div>
            )} */}

            <br />
            <hr />
            <Stripe
              blockchain={blockchain}
              accountAddress={accountAddress}
              contractAddress={contractAddress}
              token_Id={token_Id}
              nftstatus={nftStatus}
              totalstripecheckout={totalstripecheckout}
              checkoutPrice={checkoutPrice}
              stripesuccess={stripesuccess}
              // gasprice={stripegas}
              // nftPrice={nftPrice}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />
        </Modal.Footer>
      </Modal>

      {/* price update */}
      <Modal
        show={mktpriceUpdate}
        onHide={handlemktpriceUpdateClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <i
            onClick={() => {
              handlemktpriceUpdateClose();
              // handlebuyingShow();
            }}
            className="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <Modal.Title className="m-auto">
            <div className="m-auto">Edit/Update Price Details</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <br />

            <Row className="m-0">
              <Col className="">
                {nft && (
                  <img
                    // style={{height:"200px",width:"200px"}}
                    src={nft.image_preview_url}
                    className="img-fluid rounded mx-auto "
                    alt="img"
                  />
                )}
              </Col>
              <Col>
                {nft && (
                  <div>
                    <div>
                      <small>{nft.collection.name}</small>
                    </div>
                    <div className="text-break ">
                      <small>
                        <b>{nft.name}</b>
                      </small>
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  <table className="table table-borderless table-sm table-secondary">
                    <tbody>
                      <tr>
                        <th scope="row" colspan="2">
                          <small>NFT Price:</small>
                        </th>
                        <td>
                          <small>
                            {nftPrice !== null ? (
                              <b>${nftPrice}</b>
                            ) : (
                              <b>price is still not assign</b>
                            )}
                          </small>
                        </td>
                      </tr>
                      {/* <tr >
                          <th scope="row" colspan="2"><small>Est. Gas fee:</small></th>
                          <td><small> 
                          ${gasprice && Math.round(gasprice*usdrate *100) / 100+10}
                           
                                       
                                      </small></td>
                        
                        </tr>
                        <tr className="table-active">
                          <th scope="row" colspan="2" ><small>Total Amount:</small></th>
                          <td >${gasprice && Math.round(gasprice*usdrate *100) / 100+10+200}</td>
                       
                        </tr> */}
                    </tbody>
                  </table>
                </div>

                <br />
                {/* <form  onSubmit={priceupdateHandler}> */}
                <div className="form-group mx-sm mb-2">
                  <input
                    type="number"
                    placeholder="Price $"
                    onChange={(e) => setupdatingPrice(e.target.value)}
                    value={updatingPrice}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-dark btn-sm mb-2"
                  onClick={priceupdateHandler}
                >
                  Update Price
                </button>
                {/* </form> */}
              </Col>
            </Row>

            {priceUpdateResponse && priceUpdateResponse.success == true && (
              <Row>
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <strong>Price Updated Successfully!</strong>NFT new price is $
                  {priceUpdateResponse.result.Attributes.nft_price}
                  <Button
                    type="button"
                    className="btn btn-dark btn-sm mb-2"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => window.location.reload()}
                  >
                    {/* <span aria-hidden="true">&times;</span> */}OK
                  </Button>
                </div>
              </Row>
            )}

            {priceUpdateResponse && priceUpdateResponse.success == false && (
              <Row>
                <div class="alert alert-danger" role="alert">
                  {priceUpdateResponse.msg}
                </div>
              </Row>
            )}

            {/* <br />
            <hr />
            <Stripe
              blockchain={blockchain}
              accountAddress={accountAddress}
              contractAddress={contractAddress}
              token_Id={token_Id}
              nftstatus={nftStatus}
            /> */}
          </Container>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <br />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MktNftDetailPage;
