import {
  CardElement,
  useElements,
  useStripe,
  PaymentElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState, useEffect } from "react";
import stripebadge from "../assets/stripe-badge-transparent.png";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../config.json";
import { useNavigate, useParams } from "react-router-dom";

var serverIp = "http://192.168.1.21:3001/";
console.log("typeof config.SERVER_IP  : ", typeof config.SERVER_IP);
// var is_fetched = false;
if (typeof config.SERVER_IP != "object") {
  serverIp = config.SERVER_IP; //"http://3.68.139.145:80/";  "https://www.eng.nfinitymark.com/"  "https://www.mint-staging1.nfinitymark.com/"
}

// const cardStyle = {
//     style: {
//       base: {
//         color: "#32325d",
//         fontFamily: 'Arial, sans-serif',
//         fontSmoothing: "antialiased",
//         fontSize: "16px",
//         "::placeholder": {
//           color: "#32325d"
//         }
//       },
//       invalid: {
//         fontFamily: 'Arial, sans-serif',
//         color: "#fa755a",
//         iconColor: "#fa755a"
//       }
//     }
//   };

// const options={
//     clientSecret:'your clientsecret'
//    }

export default function PaymentForm({
  blockchain,
  accountAddress,
  contractAddress,
  token_Id,
  nftstatus,
  totalstripecheckout,
  checkoutPrice,
  stripesuccess,
}) {
  //  var gas = gasprice
  //  var nftpri = gasprice
  //  var total = gas+ nftpri+ 10
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0();
  console.log(user);

  const [success, setSuccess] = useState(false);
  const [blockchainn, setblockchain] = useState(blockchain);
  const [accountAddressn, setaccountAddress] = useState();
  const [contractAddressn, setcontractAddress] = useState(contractAddress);
  const [token_Idn, settoken_Id] = useState(token_Id);
  const [errormessage, seterrormessage] = useState();
  const [transfersuccess, settransfersuccess] = useState();
  const stripe = useStripe();
  const elements = useElements();

  let navigate = useNavigate();

  useEffect(() => {
    // console.log(user["http://localhost/wallet_address"]);
    console.log(user);
    let stringJson = JSON.stringify(user);
    console.log(stringJson);
    if (stringJson != null) {
      let splitObj = stringJson.split(":");

      let walletAddress = splitObj[2].split('"')[1];
      console.log(walletAddress);
      setaccountAddress(walletAddress);
    }
  }, [user]);

  // // console.log(user);
  // let stringJson = JSON.stringify(user);
  // // console.log(stringJson);
  // let splitObj = stringJson.split(":");
  // let walletAddress = splitObj[2].split('"')[1];
  // console.log(walletAddress);

  const protectedApi = async () => {
    var url = serverIp + "user/userInfo";
    const token = await getAccessTokenSilently();
    console.log(`Token : ${token}`);

    try {
      const response = await axios.get(url, {
        header: {
          autherizaation: `Bearer ${token}`,
        },
      });

      console.log(response.data);
    } catch (error) {}
  };

  // const transfer = async (e) => {
  //   const response = await axios.post(serverIp + "mkt/transferNft", {
  //     transfer_to: accountAddressn,
  //     transfer_from: "0x9c2f4Ad1d8aA678b8D5915BEB9E63684DA19d21f",
  //     token_Id: token_Idn,
  //     contract_address: contractAddressn,
  //   });

  //   if (response.data.success) {
  //     console.log("Successful payment");
  //     console.log(response);
  //     console.log(response.data);
  //     settransfersuccess(response.data);
  //   } else {
  //     console.log(response);
  //     seterrormessage(response.data.message);
  //   }
  // };

  const transfer = async (e) => {
    // var url = serverIp + "user/userInfo";
    const token = await getAccessTokenSilently();
    // console.log(`Token : ${token}`);

    const response = await axios.post(
      serverIp + "mkt/transferNft",
      {
        transfer_to: accountAddressn,
        transfer_from: "0x9c2f4Ad1d8aA678b8D5915BEB9E63684DA19d21f",
        token_Id: token_Idn,
        contract_address: contractAddressn,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      console.log("Successful payment");
      console.log(response);
      console.log(response.data);
      settransfersuccess(response.data);
    } else {
      console.log(response);
      seterrormessage(response.data.msg);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    // let Total =

    if (!error) {
      // console.log(totalstripecheckout)
      // let editedvalue = (totalstripecheckout*100).toFixed(0)
      // console.log(editedvalue)
      try {
        const token = await getAccessTokenSilently();
        const { id } = paymentMethod;
        const response = await axios.post(
          serverIp + "mkt/payment",
          {
            amount: totalstripecheckout,
            id,
            blockchainn,
            accountAddressn,
            contractAddressn,
            token_Idn,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          console.log("Successful payment");
          console.log(response.data);
          transfer();
          setSuccess(true);
          stripesuccess(true);
        } else {
          console.log(response);
          seterrormessage(response.data.msg);
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      console.log(error.message);
    }
  };

  return (
    <>
      {/* {console.log(
        blockchain,
        accountAddress,
        contractAddress,
        token_Id,
        nftstatus,
        totalstripecheckout
      )} */}
      {nftstatus == true && !success ? (
        <div className="border rounded">
          <div className="col-lg-12 ">
            <small>
              <b>
                <label className="card-element text-muted">
                  {" "}
                  Enter your Payment Details
                </label>
              </b>
            </small>
            <br />
            {/* <h8>{accountAddressn && accountAddressn}</h8> */}
          </div>

          <div className="border  rounded  m-2">
            <form className="payment-form" onSubmit={handleSubmit}>
              <div
                className="card-element m-2"
                style={{ height: "1.2em", paddingTop: "1em" }}
              >
                <CardElement />
              </div>

              <div className="card-errors" role="alert"></div>
              <br />
              <button
                className="btn btn-dark  w-100"
                onClick={() => seterrormessage(null)}
              >
                Pay ${checkoutPrice}
              </button>
            </form>
          </div>
          <div className="col-lg-12 m-2">
            <img
              // style={{height:"200px",width:"200px"}}
              src={stripebadge}
              className="img-fluid rounded mx-auto "
              alt="img"
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {success == true && (
        <div>
          <div class="alert alert-success" role="alert">
            Your Payment was Successful ! await untill tranfering the nft too
            your wallet.
          </div>
        </div>
      )}

      {errormessage != null || errormessage != undefined ? (
        <div class="alert alert-danger" role="alert">
          {errormessage}
        </div>
      ) : null}

      {transfersuccess != null || transfersuccess != undefined ? (
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">Well done!</h4>
          {transfersuccess.msg}
          <hr />
          <p className="mb-0">
            It will take some time to transfer this NFT to your wallet
          </p>
          <button
            type="button"
            class="btn btn-secondary"
            onClick={() => navigate(-1)}
          >
            OK
          </button>
        </div>
      ) : // <div class="alert alert-info" role="alert">
      //   {transfersuccess.msg}
      // </div>

      null}
    </>
  );
}
