import React from 'react'

const Loader = () => {
  return (
    <div className='m-5 py-5'>
      <div className="d-flex justify-content-center m-5">
          <div className="mt-5  spinner-border align-items-center" role="status">
            <span className="sr-only">Loading...</span>
          </div>
      </div>
    </div>
  
 
  )
}

export default Loader