import React from 'react'

const Error = ({error}) => {
  return (
    <div class="alert alert-danger" role="alert">
  {error} — <strong>check it out!</strong>
</div>
    
  )
}

export default Error