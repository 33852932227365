import config from "../../config.json";
// import Web3 from "web3";

// var web3 = new Web3(Web3.givenProvider);

var url;
// var current_node;
var openSeaDomain = config.OPENSEA_MAIN;
var X_API_KEY = config.MAIN_X_API_KEY;

export const getsinglenft = (contractAddress, tokenId,blockchain) => async (dispatch) => {
  // current_node = await web3.eth.net.getNetworkType().then();
  // console.log(
  //   "66666666666666666666666666666666666 current_node :",
  //   current_node
  // );
  // console.log(tokenId);
  // console.log("current_node---------------------------", current_node);
  // console.log(
  //   "config.main_network_address---------------------------",
  //   config.main_network_node
  // );

  dispatch({ type: "GET_GETSINGLENFT_REQUEST" });

  if (blockchain!= config.main_network_node) {
    openSeaDomain = config.OPENSEA_TEST;
    X_API_KEY = config.TEST_X_API_KEY;
  }

  url =`${openSeaDomain}asset/${contractAddress}/${tokenId}`;
  // url = `${openSeaDomain}assets?token_ids=${tokenId}&asset_contract_addresses=${contractAddress}&order_direction=desc&offset=0&limit=20`;

  // console.log("url---------------------------", url);

  const options = {
    method: "GET",
    headers: { Accept: "application/json", "X-API-KEY": X_API_KEY },
  };
  // console.log(contractAddress, tokenId);
  fetch(url, options)
    .then((response) => response.json())
    .then((response) => {
      dispatch({
        type: "GET_GETSINGLENFT_SUCCESS",
        payload: response,
      });
      // console.log(response); 
    })
    .catch((err) =>
      dispatch({ type: "GET_GETSINGLENFT_FAILED", payload: err })
    );
};
