import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import NftDetailPage from "../pages/NftDetailPage";

export default function MktNftCard({ nft }) {
  let navigate = useNavigate();

  return (
    <>
      {nft && (
        <Link
          to={`/nm/assets/${nft.contract.address}/${nft.id.tokenId}`}
          className="text-decoration-none"
        >
          {/* <Link to={`/nm/assets/${nft.asset_contract.address}/${nft.token_id}`}  className="text-decoration-none"> */}
          <div>
            <Card sx={{ maxWidth: 300 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={nft.metadata.image}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {/* {nft.collection.name && (<span className='m-0 p-0'><small>{nft.collection.name}</small></span>)} */}
                    {nft.title && (
                      <span className="m-0 p-0">
                        <small>{nft.title}</small>
                      </span>
                    )}
                  </Typography>

                  <Typography gutterBottom variant="h8" component="div">
                    <small>
                      <b>{nft.name}</b>
                    </small>
                    <small>
                      <b>{nft.name}</b>
                    </small>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Details
                </Button>
              </CardActions>
            </Card>
          </div>
        </Link>
      )}
    </>
  );
}
