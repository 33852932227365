import React, { useEffect, useState, Suspense, lazy } from "react";
import Web3 from "web3";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import NavBar from "./components/NavBar";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  FormControl,
  Dropdown,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import NftbyWallet from "./pages/NftbyWallet";
import MktNftDetailPage from "./pages/MktNftDetailPage";
import MktTranferNftPage from "./pages/MktTranferNftPage";
import Login from "./pages/Login";

// import NftMakeOfferPage from "./pages/NftMakeOfferPage";
// import WalletNfts from "./components/WalletNfts";
// import NmNfts from "./components/NmNfts";
const HomePage = lazy(() => import("./pages/HomePage"));
const ProfileNftPage = lazy(() => import("./pages/ProfileNftPage"));
const CollectionNftPage = lazy(() => import("./pages/CollectionNftPage"));
const NftDetailPage = lazy(() => import("./pages/NftDetailPage"));
const NftSellPage = lazy(() => import("./pages/NftSellPage"));

function App() {
  const [blockchain, setBlockchain] = useState();
  const [defaultAccount, setDefaultAccount] = useState();

  useEffect(() => {
    blockchainObj();
  }, [defaultAccount]);

  const blockchainObj = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_accounts" })
        .then(async (result) => {
          if (result[0] == undefined) {
            console.log("Metamask is not connected");
          } else {
            var web3 = new Web3(Web3.givenProvider);
            let current_node = await web3.eth.net.getNetworkType().then();
            setBlockchain(current_node);
            console.log(current_node);
            console.log("Your connected to: " + result[0]);
            accountChangedHandler(result[0]);
          }
        });
    } else {
      console.log("Need to install MetaMask");
      // setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  const chainChangedHandler = () => {
    window.location.reload();
  };

  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    // console.log(newAccount)
    // window.location.reload();
  };

  window.ethereum.on("accountsChanged", accountChangedHandler);

  window.ethereum.on("chainChanged", chainChangedHandler);

  return (
    <div>
      <NavBar blockchainN={blockchain} />

      {blockchain && blockchain != "main" ? (
        <div className="sticky-top mt-5 w-100 bg-warning text-dark">
          <small>
            <p className="p-3 mb-0 pb-0 text-center">
              You're viewing data from the test network, & your wallet is
              connected to the {blockchain} network (Ethereum).{" "}
            </p>
          </small>
          {/* <Alert   variant='warning'>
            <small className='m-0 p-0'>
            You're viewing data from the test network, & your wallet is connected to the rinkyby network (Ethereum). 
            </small>
          </Alert> */}
        </div>
      ) : null}

      {/* <Router> */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/account"
            element={
              <ProfileNftPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          <Route
            path="/mkt"
            element={
              <MktTranferNftPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/account/:walletAddress"
            element={
              // <ProfileNftPage
              //   blockchain={blockchain}
              //   accountAddress={defaultAccount}
              // />
              <NftbyWallet
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          <Route
            path="/collection/:name"
            element={
              <CollectionNftPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />

          <Route
            path="/assets/:contractAddress/:token_Id"
            element={
              <NftDetailPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          <Route
            path="/nm/assets/:contractAddress/:token_Id"
            element={
              <MktNftDetailPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          <Route
            path="/assets/:contractAddress/:token_Id/sell"
            element={
              <NftSellPage
                blockchain={blockchain}
                accountAddress={defaultAccount}
              />
            }
          />
          {/* <Route  path='/assets/:contractAddress/:token_Id/makeoffer' element={<NftMakeOfferPage/> }/> */}
        </Routes>

        {/* <Suspense fallback={<Loader/>}>
            <Footer/>
          </Suspense> */}
      </Suspense>
      {/* </Router> */}
    </div>
  );
}

export default App;
