import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { getNftsByWalletAdReducer } from './getnftsbywallet/getnftwalletReducer';
import { getsinglenftReducer } from './fetchsinglenft/fetchsinglenftReducer'
import { fetchcollectionbynameReducer } from './fetchcollectionbyname/fetchcollectionbynameReducer';



const finalReducer = combineReducers({

    // getNftsByWalletAdReducer : getNftsByWalletAdReducer,
    getsinglenftReducer : getsinglenftReducer,
    fetchcollectionbynameReducer : fetchcollectionbynameReducer
    
})

// have to write one initial state for local storage whenever page reloaded it check for the initial state for all reducers


const initialState = {


}


const composeEnhancers = composeWithDevTools({
    // Specify here name, actionsBlacklist, actionsCreators and other options
});

const store = createStore(finalReducer, initialState ,  composeEnhancers(
    applyMiddleware(thunk)
    // other store enhancers if any
))

export default store;