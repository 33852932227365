import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";

const PUBLIC_KEY =
  "pk_test_51LNCCvKMGnK3kZmnYsNYKtG7ua4tnvWyz4z9xjJTN26bMP8K844RmODmmVV6anqfxAA6ilS0fPDYCoo0Djo4kX6G00dc1s2Nph";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

function Stripe({
  blockchain,
  accountAddress,
  contractAddress,
  token_Id,
  nftstatus,
  totalstripecheckout,
  checkoutPrice,
  stripesuccess,
  //comment
}) {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm blockchain={blockchain} accountAddress={accountAddress} contractAddress={contractAddress} token_Id={token_Id} nftstatus={nftstatus} totalstripecheckout={totalstripecheckout} checkoutPrice={checkoutPrice} stripesuccess={stripesuccess}/>
		</Elements>
  )
}

export default Stripe;
