export const fetchcollectionbynameReducer = (state=[] , action) => {
    
    switch(action.type)
    {
        case  'GET_COLLECTION_REQUEST' : return{
            ...state ,
            loading : true
        }
        case 'GET_COLLECTION_SUCCESS' : return{
            ...state ,
            loading : false,
            nfts : action.payload
        }
        case 'GET_COLLECTION_FAILED' : return{
            ...state ,
            loading : false,
            error : true
        }
        default : return{state}

    }

}