import React, { useState, useEffect } from "react";
import NftCard from "../components/NftCard";
import config from "../config.json";
// import Web3 from "web3";
import Loader from "../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import NmNftCard from "../components/NmNftCard";
import MktNftCard from "../components/MktNftCard";

import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "../components/Pagination";
import MktPagination from "../components/MktPagination";

const alchemyApiKey = "9flwavyzv0ohrgaybypvmbllbz3y5yt4";
var url;
// var current_node;
var openSeaDomain = config.OPENSEA_MAIN;
var X_API_KEY = config.MAIN_X_API_KEY;

const MktTranferNftPage = ({ blockchain, accountAddress }) => {
  const [loading, setLoading] = useState(true);
  const [nfts, setNfts] = useState([]);
  const [walletAvailable, setWalletAvailable] = useState();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();
  console.log(nfts);

  const [viewNftList, setViewNftList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(18);
  const [currentPage, setCurrentPage] = useState(0);
  // let { walletAddress } = useParams();
  let walletAddress = "0x9c2f4ad1d8aa678b8d5915beb9e63684da19d21f"; //"0x8a316EdEe51B65e1627C801DBc09AA413C8f97C2";

  const nftsbywalletAddress = async () => {
    console.log(blockchain, accountAddress);

    if (blockchain != config.main_network_node) {
      openSeaDomain = config.OPENSEA_TEST;
      X_API_KEY = config.TEST_X_API_KEY;
    }

    const options = {
      method: "GET",
      headers: { Accept: "application/json", "X-API-KEY": X_API_KEY },
    };
    url = `${openSeaDomain}assets?owner=${walletAddress}&order_direction=desc&offset=0&limit=200`;

    fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.assets);
        setNfts(response.assets);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
    //   })
  };

  const nftByWalletAddressAlchemy = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const baseURL =
      "https://eth-rinkeby.alchemyapi.io/nft/v2/" + alchemyApiKey + "/getNFTs";
    const ownerAddr = "0x8a316EdEe51B65e1627C801DBc09AA413C8f97C2";
    const pageKey = "";
    const fetchURL = `${baseURL}?owner=${ownerAddr}&withMetadata=true`;

    // const fetchURL = `${baseURL}?owner=${ownerAddr}`; -> without page key -> fetch 100 only

    fetch(fetchURL, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        console.log(response.ownedNfts);
        setNfts(response.ownedNfts);
        setLoading(false);
      })

      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    nftsbywalletAddress();
    // nftByWalletAddressAlchemy();
  }, []);

  const setPagestates = (currentPage) => {
    console.log(`70  currentPage ${currentPage}`);
    setCurrentPage(currentPage);
  };

  const setNoofItemState = (itemsPerPage) => {
    console.log(`76 itemsPerPage ${itemsPerPage} `);
    setItemsPerPage(itemsPerPage);
  };

  const msg = () => {
    return (
      <div>
        {!isAuthenticated && (
          <div className="alert alert-danger m-0" role="alert">
            Please Login First to Buy NFT
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <div style={{ paddingTop: "80px" }}>
          <Pagination // MktPagination
            nftList={nfts}
            setViewNftList={setViewNftList}
            onPageChange={setPagestates}
            onNoOfItemChange={setNoofItemState}
            noOfItemsPage={itemsPerPage}
            presentPge={currentPage}
            instance={1}
          />
        </div>

        {walletAvailable == false && (
          <div className="alert alert-dark  mt-5 pt-5" role="alert">
            connect your metamask wallet first! .
            <span onClick={() => window.location.reload()}>
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>
          </div>
        )}

        <div>
          {!isAuthenticated && (
            <div className="alert alert-danger m-0" role="alert">
              Please Login First to Buy NFT
            </div>
          )}
        </div>
        <div className="row justify-content-center ml-2 mr-2 mt-5 pt-5">
          {viewNftList &&
            (loading ? (
              <Loader />
            ) : (
              viewNftList.map((nft) => {
                return (
                  <div
                    key={nft.id}
                    className="col-md-3 m-2 p-0 bg-white rounded"
                  >
                    <NmNftCard nft={nft} />
                    {/* <MktNftCard nft={nft} /> */}
                  </div>
                );
              })
            ))}
        </div>
      </div>
    </>
  );
};

export default MktTranferNftPage;
