export const getsinglenftReducer = (state = [], action) => {
    switch (action.type) {
      case "GET_GETSINGLENFT_REQUEST":
        return {
          ...state,
          loading: true,
        };
      case "GET_GETSINGLENFT_SUCCESS":
        return {
          ...state,
          loading: false,
          nft: action.payload,
          error: false,
        };
      case "GET_GETSINGLENFT_FAILED":
        return {
          ...state,
          loading: false,
          error: true,
        };
      default:
        return { ...state, loading: true };
    }
  };